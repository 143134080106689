<template>
  <v-container>
    <div v-if="role.role == 'participant'">
      <p style="font-weight: 500" class="primary--text text-center mb-5">
        Выберите цель оценки:
      </p>
      <div>
        <div>
          <v-btn
            block
            class="white--text primary mb-5 py-1"
            @click="$emit('selected', 'createExternal')"
            >Найм</v-btn
          >
        </div>
        <div>
          <v-btn
            block
            class="white--text primary mb-5 py-1"
            @click="$emit('selected', 'createPromotion')"
            >Продвижение</v-btn
          >
        </div>
        <div>
          <v-btn
            block
            class="white--text primary mb-5 py-1"
            @click="$emit('selected', 'createDevelopment')"
            >Развитие</v-btn
          >
        </div>
      </div>
    </div>
    <div v-else>
      <p style="font-weight: 500" class="primary--text text-center mb-5">
        Выберите тип пользователя:
      </p>
      <div v-if="role.hasExternal">
        <div>
          <v-btn
            block
            class="white--text primary mb-5 py-1"
            @click="$emit('selected', 'createExternal')"
            >Внешний {{ role.title.toLowerCase() }}</v-btn
          >
        </div>
        <div>
          <v-btn
            block
            class="white--text primary py-1"
            @click="$emit('selected', 'create')"
            >Внутренний {{ role.title.toLowerCase() }}</v-btn
          >
        </div>
      </div>
      <div v-else>
        <div>
          <v-btn
            block
            class="white--text primary py-1"
            @click="$emit('selected', 'create')"
            >Добавить {{ role.cases[0] }}</v-btn
          >
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  props: ["role"],
  setup(_, { emit }) {
    if (_.role.role != "participant" && !_.role.hasExternal) {
      emit("selected", "create");
    }
  },
};
</script>
