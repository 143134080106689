<template>
  <v-container v-if="isAuth">
    <Header />
    <v-row justify="space-between" align="start" class="mt-7 mb-3">
      <v-spacer></v-spacer>
      <v-col class="d-flex justify-end">
        <div>
          <span class="filter-btn" @click="switchToggle">
            {{ !toggle ? "Показать фильтры" : "Скрыть фильтры" }}
          </span>
        </div>
      </v-col>
    </v-row>

    <ClientList :role="role" :filterToggle="toggle" />
  </v-container>
</template>

<script>
import { useToggle, useAuthRedirect } from "@/use/helpers";
import Header from "@/components/shared/assets/Header";
import ClientList from "@/components/superadmin/ClientList";

export default {
  setup(_, { root }) {
    const { isAuth, authData } = useAuthRedirect(root);

    const role = {
      title: "Клиент",
      view: "client",
      cases: ["клиента", "клиентов"],
      hasExternal: false,
      noPassword: true,
    };

    return {
      isAuth,
      authData,
      role,
      ...useToggle(),
    };
  },
  components: {
    Header,
    ClientList,
  },
};
</script>

<style lang="scss" scoped></style>
