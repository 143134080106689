<template>
  <div class="card-form my-1">
    <p
      class="dark--text text-center ma-auto"
      style="font-weight: 500; max-width: 300px"
    >
      Пожалуйста, загрузите список {{ role.cases[1] }}
    </p>
    <v-card-subtitle class="mt-1">
      Допустимое расширение файлов xlsx
    </v-card-subtitle>
    <v-card-text>
      <v-sheet
        title="Перетащите загружаемые файлы сюда"
        width="100%"
        class="px-2 drop"
        style="cursor: pointer"
      >
        <input
          ref="file"
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          style="display: none"
        />
        <v-row justify="center" class="mb-9">
          <span class="text-center"
            >Перетащите загружаемые <br />
            файлы сюда</span
          >
        </v-row>
        <v-row justify="center" class="mb-9">
          <span class="text-center">Или</span>
        </v-row>
        <v-row justify="center">
          <!--<v-file-input
                  accept = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  counter
                  small-chips
                  show-size
                  truncate-length="21"
              ></v-file-input>-->
          <v-btn class="white--text primary" style="min-width: 220px"
            >Добавить файл</v-btn
          >
        </v-row>
      </v-sheet>
    </v-card-text>
    <v-btn block class="white--text primary mt-8">Скачать шаблон</v-btn>
  </div>
</template>

<script>
import { ref, onMounted } from "@vue/composition-api";

export default {
  props: ["role"],
  setup(_, { root }) {
    const dragover = ref(false);
    const formUpload = ref(false);

    const filesSelected = () => {};

    onMounted(() => {
      const dropZone = root.$el.querySelector(".drop");
      const fileupload = dropZone.querySelector("input");
      const addBtn = dropZone.querySelector("button");

      if (dropZone) {
        dropZone.addEventListener("dragenter", e => {
          e.preventDefault();
          dragover.value = true;
        });
        dropZone.addEventListener("dragleave", e => {
          e.preventDefault();
          dragover.value = false;
        });
        dropZone.addEventListener("dragover", e => {
          e.preventDefault();
          dragover.value = true;
        });
        dropZone.addEventListener("drop", e => {
          e.preventDefault();
          const dragevent = e;
          if (dragevent.dataTransfer) {
            filesSelected(dragevent.dataTransfer.files);
          }
        });
        addBtn.addEventListener("click", e => {
          e.preventDefault();
          if (fileupload) {
            fileupload.click();
          }
        });
      }
    });

    return {
      dragover,
      formUpload,
    };
  },
};
</script>

<style lang="scss" scoped>
.card-form {
  margin: auto;
  &__title {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.375rem;
  }

  &__subtitle {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    color: #afafaf;
  }

  .v-sheet {
    background: rgba(231, 233, 243, 0.3);
    border: 1px dashed #4c5ca7;
    box-sizing: border-box;
    border-radius: 5px;
    padding-top: 25px;
    padding-bottom: 25px;

    span {
      font-size: 0.875rem;
      line-height: 1.0625rem;
      font-weight: 600;
      color: rgba(175, 175, 175, 0.7);
    }
  }
}
</style>
