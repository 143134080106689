<template>
  <div>
    <DataTable
      :headers="headers"
      :data="clients"
      :role="role"
      :filterToggle="filterToggle"
      :loaded="!loading"
      :defaultPagination="true"
      @on-create="create"
      @on-update="update"
      @on-remove="remove"
    />
  </div>
</template>

<script>
import { ref, computed, onBeforeMount } from "@vue/composition-api";
import { useSetFilters } from "@/use/helpers";
import useTableHeaders from "@/use/tableHeaders";
import DataTable from "@/components/shared/assets/table/DataTable";

export default {
  props: ["role", "filterToggle"],
  setup(_, { root }) {
    const store = root.$store;

    const loading = ref(true);

    onBeforeMount(async () => {
      await store.dispatch("fetchClients");
      loading.value = false;
    });

    const { clientHeaders } = useTableHeaders(store);
    const clients = computed(() => ({
      content: store.getters.getClients.map(el => {
        for (let key in el) {
          if (!el[key] && typeof el[key] !== "boolean") {
            el[key] = "-";
          }
        }

        return el;
      }),
    }));
    const headers = computed(() => clientHeaders);
    const content = computed(() => clients.value.content);

    const create = data => store.dispatch("createClient", data);

    const update = (_, id, allData) =>
      store.dispatch("updateClient", [allData, id]);

    const remove = data => store.dispatch("removeClients", data);

    return {
      loading,
      ...useSetFilters(headers, content),
      headers,
      clients,
      create,
      update,
      remove,
    };
  },
  components: {
    DataTable,
  },
};
</script>

<style lang="scss" scoped></style>
