<template>
  <div class="field-wrap">
    <v-select
      v-if="options.type == 'combobox'"
      dense
      outlined
      item-text="name"
      item-value="id"
      return-object
      :label="options.text"
      :items="fill"
      v-model="selectedValue"
      :required="!options.isValid"
      :rules="[v => options.isValid || '']"
      validate-on-blur
      @change="updateSelect"
      :ref="options.ref"
      @click="
        options.click && options.click({ refs: $refs, refName: options.ref })
      "
    >
    </v-select>
    <v-select
      v-else-if="options.type == 'multibox'"
      hide-details
      dense
      outlined
      item-text="name"
      item-value="id"
      :label="options.text"
      :items="fill"
      multiple
      chips
      validate-on-blur
      v-model="model[options.model]"
      class="chip-wrap"
    >
      <template v-slot:selection="{ item }">
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-select>
    <list
      v-else-if="options.type == 'list'"
      :data="model[options.model]"
      string-array
      :cases="{ title: options.text }"
    >
    </list>
    <v-text-field
      v-else-if="options.type == 'date'"
      outlined
      :required="!options.isValid"
      :rules="[v => options.isValid || '']"
      :type="options.type"
      :label="options.text"
      :value="formattedDate"
      @change="changeValue"
    ></v-text-field>
    <v-text-field
      v-else
      outlined
      :required="!options.isValid"
      :rules="[v => options.isValid || '']"
      :type="options.type"
      :label="options.text"
      v-model="model[options.value]"
    ></v-text-field>
  </div>
</template>

<script>
import { useFieldValidation } from "@/use/form";
import { computed, watch, ref, onBeforeMount } from "@vue/composition-api";

export default {
  props: ["model", "options"],
  setup(_, { root }) {
    useFieldValidation(_.options, _.model[_.options.value]);
    watch(
      () => _.model[_.options.value],
      val => {
        useFieldValidation(_.options, val);
      }
    );
    watch(
      () => _.model,
      async () => {
        if (_.options.method) {
          await _.options.method(findSelectedValue().id);
        }
        selectedValue.value = findSelectedValue();
      }
    );

    const formattedDate = computed(() => {
      return (
        _.model[_.options.value] &&
        _.model[_.options.value].split(".").reverse().join("-")
      );
    });

    const changeValue = val => {
      _.model[_.options.value] = val;
    };

    const fill = computed(() => {
      return (_.options.fill && _.options.fill.value) || [];
    });
    watch(fill, () => {
      selectedValue.value = findSelectedValue();
      useFieldValidation(_.options, selectedValue.value.name);
    });

    const findSelectedValue = () => {
      return (
        fill &&
        (fill.value.find(el => el.name == _.model[_.options.value]) ||
          fill.value.find(el => el.id == _.model[_.options.value]) ||
          {})
      );
    };

    const updateSelect = async val => {
      changeValue(val.id);
      useFieldValidation(_.options, val.id);
      if (_.options.method) {
        await _.options.method(val.id);
      }
    };

    const selectedValue = ref({});

    onBeforeMount(async () => {
      if (_.options.method) {
        if (!_.model[_.options.value]) {
          root.$store.commit("setCurrentProfile", {});
        } else {
          await _.options.method(findSelectedValue().id);
        }
      }
      selectedValue.value = findSelectedValue();
    });

    return {
      formattedDate,
      changeValue,
      selectedValue,
      updateSelect,
      fill,
    };
  },
};
</script>

<style lang="scss"></style>
