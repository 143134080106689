<template>
  <div>
    <DataFilter v-if="filterToggle" />

    <infinity-collection @scrolled="$emit('on-read')">
      <v-data-table
        :mobile-breakpoint="null"
        fixed-header
        :loading="!loaded"
        class="elevation-6"
        item-key="id"
        :single-select="false"
        show-select
        :headers="filteredHeaders"
        :items="dataContent"
        :items-per-page="dataContent.length"
        v-model="selectedRow"
        :hide-default-footer="!defaultPagination"
        @click:row="rowEdit"
        height="450px"
        :custom-sort="customSort"
      >
      </v-data-table>
    </infinity-collection>
    <div class="d-flex justify-space-between mt-8">
      <div :class="['d-flex', { '--disabled': !selectedRow.length }]">
        <v-btn
          class="white--text primary mr-9"
          style="min-width: 220px"
          @click="switchToggle(true, 'editRows')"
        >
          Редактировать ({{ selectedRow.length }})
        </v-btn>
        <confirm text="Удалить выбранные записи?" @confirm="rowRemoved">
          Удалить ({{ selectedRow.length }})
        </confirm>
      </div>
      <v-btn
        class="white--text primary"
        style="min-width: 235px"
        @click="switchToggle(true, 'select')"
      >
        Добавить {{ role.cases[0] }}
      </v-btn>
    </div>
    <modal :dialog="toggle" :width="toggle.width">
      <SelectCreateType
        v-if="toggle.view == 'select'"
        :role="role"
        @selected="switchToggle(true, $event)"
      />

      <DataForm
        v-else-if="toggle.view == 'create'"
        :fields="headers"
        :rowData="rowData"
        :role="role"
        :external="false"
        :dialog="toggle"
        @save="rowSaved"
      />
      <DataForm
        v-else-if="toggle.view == 'createExternal'"
        :fields="headers"
        :rowData="rowData"
        :role="role"
        :external="true"
        :dialog="toggle"
        @save="rowSaved"
      />
      <DataForm
        v-else-if="toggle.view == 'createPromotion'"
        :fields="headers"
        :rowData="rowData"
        :role="role"
        :dialog="toggle"
        @save="rowSaved"
      />
      <DataForm
        v-else-if="toggle.view == 'edit'"
        :fields="headers"
        :rowData="rowData"
        :role="role"
        :edit="true"
        :external="rowData.isExternal"
        :dialog="toggle"
        @reset-password="resetPassword"
        @save="rowSaved"
      />
      <DataForm
        v-else-if="toggle.view == 'editRows'"
        :fields="headers"
        :rowData="selectedRow[0]"
        :role="role"
        :edit="true"
        :rows="selectedRow"
        :external="selectedRow[0].isExternal"
        :dialog="toggle"
        @reset-password="resetPassword"
        @save="rowSaved"
      />
      <Upload v-else-if="toggle.view == 'upload'" :role="role" />
    </modal>
  </div>
</template>

<script>
import { ref, reactive, watch, computed } from "@vue/composition-api";
import DataFilter from "@/components/shared/assets/table/DataFilter";
import DataForm from "@/components/shared/assets/table/DataForm";
import SelectCreateType from "@/components/shared/assets/table/SelectCreateType";
import Upload from "@/components/admin/assets/users/Upload";
import { customSort } from "@/use/tableHeaders";

export default {
  props: [
    "headers",
    "data",
    "role",
    "filterToggle",
    "loaded",
    "defaultPagination",
  ],
  setup(_, { emit }) {
    const filteredHeaders = computed(() => {
      return [..._.headers.filter(h => !h.noTable)];
    });
    const dataContent = computed(() => {
      return _.data.content.map(el => {
        const fullName = `${el["lastName"]} ${el["firstName"]} ${el["middleName"]}`;
        el.fullName = fullName.replaceAll("-", "");
        return el;
      });
    });

    const selectedRow = ref([]);

    const toggle = reactive({
      isOpen: false,
      view: "",
      width: null,
    });

    const switchToggle = (isOpen, view = "", width = 500) => {
      toggle.isOpen = isOpen;
      toggle.view = view;
      toggle.width = width;
    };

    const rowData = ref({ createType: 0 });
    const rowEdit = data => {
      rowData.value = data;
      switchToggle(true, "edit");
    };

    const rowSaved = (data, id, allData) => {
      if (id) {
        emit("on-update", data, id, allData);
      } else {
        emit("on-create", data, allData);
      }
    };

    const rowRemoved = row => {
      emit("on-remove", row ? [row] : [...selectedRow.value]);
      selectedRow.value = [];
    };

    const clearRowData = () => {
      rowData.value = { createType: 0 };
    };
    watch(toggle, val => {
      if (!val.isOpen) {
        clearRowData();
      }
    });

    const resetPassword = email => {
      emit("on-reset-password", email);
    };

    return {
      customSort,
      selectedRow,
      rowData,
      rowEdit,
      rowSaved,
      rowRemoved,
      toggle,
      switchToggle,
      resetPassword,
      filteredHeaders,
      dataContent,
    };
  },
  components: {
    DataFilter,
    DataForm,
    SelectCreateType,
    Upload,
  },
};
</script>
